import { arbitrum, mainnet, optimism, sonic } from "viem/chains";
import { createConfig, http } from "wagmi";
import { safe } from "wagmi/connectors";

const connector = safe({
  allowedDomains: [/app.safe.global$/],
});

export const wagmiConfig = createConfig({
  chains: [mainnet, arbitrum, optimism, sonic],
  connectors: [connector],
  transports: {
    [mainnet.id]: http(),
    [arbitrum.id]: http(),
    [optimism.id]: http(),
    [sonic.id]: http(),
  },
});
