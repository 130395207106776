import axios from "axios";
import {
  SafeClientServiceResponse,
  SafeTransactionResponse,
  SafeTransactionServiceResponse,
  SafeTx,
  Transaction,
  TxID,
} from "types/transactions";
import { Address, encodeFunctionData, Hash } from "viem";

import { json_parse } from "./bigint-serializer";
import { TRANSACTION_SERVICE_URL } from "./constants";

const parseTxId = (id: TxID) => {
  const [__, multisig, safeTxHash] = id.split("_");
  return {
    multisig: multisig as Address,
    safeTxHash: safeTxHash as Hash,
  };
};

export const encodeTransaction = (tx: SafeTx) => {
  const abi = [{ ...tx.contractMethod, outputs: [], type: "function" }];
  const functionName = tx.contractMethod.name;

  const args = tx.contractMethod.inputs.map(input => {
    const arg = tx.contractInputsValues[input.name];

    try {
      return json_parse(arg);
    } catch {
      return arg;
    }
  });

  const data = encodeFunctionData({
    abi,
    functionName,
    args,
  });

  return {
    to: tx.to,
    value: tx.value,
    data,
  };
};

export const getSafeTransactionList = async (
  chainId: number | string,
  safeAddress: `0x${string}`,
) => {
  const baseUrl = TRANSACTION_SERVICE_URL[Number(chainId)];

  let transactions: SafeTransactionResponse[];

  try {
    const response = await axios.get<SafeTransactionServiceResponse>(
      `${baseUrl}api/v1/safes/${safeAddress}/multisig-transactions/?queued=true&executed=false`,
    );

    if (!response.data) {
      throw new Error("Invalid response");
    }

    transactions = response.data.results;
  } catch {
    const txsResponse = await axios.get<SafeClientServiceResponse>(
      `https://safe-client.safe.global/v1/chains/${chainId}/safes/${safeAddress}/multisig-transactions`,
    );

    const response = await Promise.all(
      txsResponse.data.results
        .filter(
          tx =>
            tx.transaction.txStatus === "AWAITING_CONFIRMATIONS" ||
            tx.transaction.txStatus === "AWAITING_EXECUTION",
        )
        .map(tx => {
          const { safeTxHash } = parseTxId(tx.transaction.id);
          return axios.get(
            `https://safe-client.safe.global/v1/chains/${chainId}/transactions/${safeTxHash}`,
          );
        }),
    );

    transactions = response.map(
      resp =>
        ({
          to: resp.data.txData?.to.value,
          data: resp.data.txData?.hexData,
          safeTxHash: parseTxId(resp.data.txId)?.safeTxHash,
          dataDecoded: resp.data.txData.dataDecoded,
        }) as SafeTransactionResponse,
    );
  }

  const result = transactions.map(({ safeTxHash, dataDecoded, data, to }) => {
    // Transaction has only one action
    if (!dataDecoded?.parameters || dataDecoded.parameters.length > 1) {
      return {
        safeTxHash,
        transactions: [{ data, to }],
      };
    }

    if (dataDecoded.parameters.length === 0) {
      return {
        safeTxHash,
        transactions: [],
      };
    }

    // Transaction has multiple actions
    if (dataDecoded.parameters.length === 1) {
      if (dataDecoded.parameters?.[0].valueDecoded !== undefined) {
        const transactions = dataDecoded.parameters?.[0].valueDecoded.map(
          ({ to, data }) => ({ to, data }),
        );

        return {
          safeTxHash,
          transactions,
        };
      }
      return {
        safeTxHash,
        transactions: [],
      };
    }

    return {
      safeTxHash,
      transactions: [
        {
          data,
          to,
        },
      ],
    };
  });

  return result as Transaction[];
};
