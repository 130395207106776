import {
  Col,
  Description,
  Loader,
  Title,
  Typography,
} from "components/elements";
import {
  selectBatchesInfo,
  selectBatchesLength,
} from "features/batches/batchesSlice";
import { useAppSelector } from "hooks";
import { Fragment } from "react";
import { useAccount } from "wagmi";

import { Batch } from "./Batch";
import { BundleBox } from "./styles";

export function Batches() {
  const { chainId, address } = useAccount();

  const { batchesInfo, status, error } = useAppSelector(
    selectBatchesInfo(chainId, address),
  );

  const { length } = useAppSelector(selectBatchesLength);

  return (
    <Col $gap={4}>
      <Title>Batches</Title>
      {status === "loading" ? (
        <Loader />
      ) : status === "succeeded" ? (
        <>
          <BundleBox $border>
            <Col $pb={4}>
              <Typography>
                Found <b>{length}</b> batches
              </Typography>
              <Description>
                Only <b>{batchesInfo.length}</b> batches eligible for this safe:
              </Description>
            </Col>
          </BundleBox>
          {batchesInfo.map((batch, index) => (
            <Fragment key={`batch-${index}`}>
              <Batch id={batch.id} />
            </Fragment>
          ))}
        </>
      ) : (
        <Typography>Something went wrong: {error}</Typography>
      )}
    </Col>
  );
}
