import { HOUR_24 } from "@gearbox-protocol/sdk-gov";
import {
  Badge,
  Card,
  Col,
  Description,
  ErrorText,
  Row,
  Typography,
  WarningText,
} from "components/elements";
import dayjs, { extend, unix } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { selectBatchInfo } from "features/batches/batchesSlice";
import { useAppSelector } from "hooks";
import { useMemo, useState } from "react";
import { DELAY_BY_NETWORK } from "utils/constants";
import { useAccount } from "wagmi";

import { TransactionTable } from "../TransactionTable";
import { ConfirmDialog } from "./ConfirmDialog";
import { SignButton } from "./SignButton";

extend(relativeTime);

export interface BatchId {
  id: number;
}

export function Batch({ id }: BatchId) {
  const loadingState = useState<boolean>(false);
  const { chainId } = useAccount();

  const { batchInfo, status } = useAppSelector(selectBatchInfo(id));

  const [errorType, errorMessage] = useMemo(() => {
    if (!batchInfo || !chainId) {
      return [false, undefined];
    }

    if (!batchInfo?.eta) {
      return ["warning", "Unable to get ETA"];
    }

    const delay = DELAY_BY_NETWORK[chainId];

    const minEta = dayjs().unix() + delay;
    const lowWarnEta = dayjs().unix() + delay + HOUR_24;
    const highWarnEta = dayjs().unix() + delay + 7 * HOUR_24;

    if (batchInfo.eta < minEta) {
      return ["error", "ETA is too low."];
    }
    if (batchInfo.eta < lowWarnEta) {
      const daysLeft = 1 + delay / HOUR_24;
      return [
        "warning",
        `ETA is less than ${daysLeft > 1 ? `${daysLeft} days` : "a day"} in the future. You have only ${unix(
          batchInfo.eta - delay,
        ).fromNow(true)} to sign and execute this batch.`,
      ];
    }
    if (batchInfo.eta > highWarnEta) {
      return [
        "warning",
        `ETA is over ${unix(batchInfo.eta).fromNow(true)} away.`,
      ];
    }

    return [false, undefined];
  }, [chainId, batchInfo]);

  if (status !== "succeeded" || !batchInfo) {
    return <></>;
  }

  return (
    <Card>
      <Col $noGutters $gap={2}>
        <Col $noGutters>
          <Row $justify="space-between" $align="center" $noGutters>
            <Typography $weight="bold">
              {batchInfo.meta.name} #{id}
            </Typography>
            <Row $noGutters $gap={2}>
              <Badge
                $color={batchInfo.isSigned ? "success" : "neutral"}
                $size="sm"
              >
                {batchInfo.isSigned ? "Signed" : "Unsigned"}
              </Badge>
              {batchInfo.isHashApproved && (
                <Badge
                  $color={batchInfo.isHashApproved ? "success" : "neutral"}
                  $size="sm"
                >
                  Approved Hash
                </Badge>
              )}
            </Row>
          </Row>
          <Col>
            <Description>
              <b>Number of actions:</b> {batchInfo.transactions.length}
            </Description>
            <Description>
              <b>Batch type:</b> {batchInfo.type}
            </Description>
            {batchInfo.eta && (
              <Description>
                <b>ETA:</b> {unix(batchInfo.eta).format("DD.MM.YYYY HH:mm")} (
                {batchInfo.eta})
              </Description>
            )}
            {errorType === "error" && errorMessage && (
              <ErrorText>
                <b>Error:</b> {errorMessage}
              </ErrorText>
            )}
            {errorType === "warning" && errorMessage && (
              <WarningText>
                <b>Warning:</b> {errorMessage}
              </WarningText>
            )}
          </Col>
        </Col>

        {!batchInfo.isSigned && (
          <>
            <TransactionTable {...batchInfo} />
            <Row $justify="flex-end" $noGutters>
              {batchInfo.isPreviousSigned ? (
                <SignButton
                  loadingState={loadingState}
                  transactions={batchInfo.transactions.map(tx => tx.encoded)}
                  disabled={errorType === "error"}
                />
              ) : (
                <ConfirmDialog
                  loadingState={loadingState}
                  transactions={batchInfo.transactions.map(tx => tx.encoded)}
                  name={`${batchInfo.meta.name} #${id}`}
                />
              )}
            </Row>
          </>
        )}
      </Col>
    </Card>
  );
}
