import { readContract, readContracts } from "@wagmi/core";
import {
  Address,
  encodeFunctionData,
  encodePacked,
  Hash,
  parseAbi,
  zeroAddress,
} from "viem";

import { BatchInfo } from "../features/batches/batches.types";
import { BatchBundle } from "../types/batches";
import { isBatchBundleArray } from "./batches";
import { CHAIN_ID } from "./constants";
import { encodeTransaction } from "./transactions";
import { wagmiConfig } from "./wagmi";

const multisend = "0x40a2accbd92bca938b02010e17a5b8929b49130d";

export const getApprovedHashes = async (
  rawBatches: BatchBundle[] | BatchInfo[],
) => {
  const safeBatches = !isBatchBundleArray(rawBatches)
    ? rawBatches
    : rawBatches
        .map(bundle =>
          bundle.safeBatches.map(batch => ({ ...batch, type: bundle.type })),
        )
        .flat()
        .map(batch => ({
          type: batch.type,
          chainId: batch.chainId,
          eta: Number(batch.transactions[0]?.contractInputsValues?.eta),
          safeAddress: batch.safeAddress,
          meta: batch.meta,
          transactions: batch.transactions.map(tx => ({
            contractMethod: tx.contractMethod,
            encoded: encodeTransaction(tx),
          })),
        }));

  const nonces: Record<Address, number> = {};
  for (const batch of safeBatches) {
    if (!Object.keys(nonces).includes(batch.safeAddress.toLowerCase())) {
      const nonce = await readContract(wagmiConfig, {
        address: batch.safeAddress,
        abi: parseAbi(["function nonce() view returns (uint256)"]),
        functionName: "nonce",
        args: [],
        chainId: Number(batch.chainId) as CHAIN_ID,
      });

      nonces[batch.safeAddress.toLowerCase() as Address] = Number(nonce);
    }
  }

  const owners: Record<Address, Address[]> = {};
  for (const batch of safeBatches) {
    if (!Object.keys(owners).includes(batch.safeAddress.toLowerCase())) {
      const safeOwners = await readContract(wagmiConfig, {
        address: batch.safeAddress,
        abi: [
          {
            type: "function",
            inputs: [],
            name: "getOwners",
            outputs: [
              { name: "", internalType: "address[]", type: "address[]" },
            ],
            stateMutability: "view",
          },
        ],
        functionName: "getOwners",
        args: [],
        chainId: Number(batch.chainId) as CHAIN_ID,
      });

      owners[batch.safeAddress.toLowerCase() as Address] =
        safeOwners as Address[];
    }
  }

  const contracts = safeBatches.map(
    ({ transactions, safeAddress, chainId }) => {
      const multiSendData = transactions
        .map(tx => {
          return encodePacked(
            ["uint8", "address", "uint256", "uint256", "bytes"],
            [
              0,
              tx.encoded.to as Address,
              BigInt(tx.encoded.value),
              BigInt(tx.encoded.data.replace("0x", "").length / 2),
              tx.encoded.data,
            ],
          ).replace("0x", "");
        })
        .join("");

      const multisendCall = encodeFunctionData({
        abi: parseAbi(["function multiSend(bytes memory transactions)"]),
        functionName: "multiSend",
        args: [`0x${multiSendData}`],
      });

      const nonce = nonces[safeAddress.toLowerCase() as Address];
      const contract = {
        address: safeAddress,
        abi: [
          {
            type: "function",
            inputs: [
              { name: "to", internalType: "address", type: "address" },
              { name: "value", internalType: "uint256", type: "uint256" },
              { name: "data", internalType: "bytes", type: "bytes" },
              { name: "operation", internalType: "uint8", type: "uint8" },
              { name: "safeTxGas", internalType: "uint256", type: "uint256" },
              { name: "baseGas", internalType: "uint256", type: "uint256" },
              { name: "gasPrice", internalType: "uint256", type: "uint256" },
              { name: "gasToken", internalType: "address", type: "address" },
              {
                name: "refundReceiver",
                internalType: "address",
                type: "address",
              },
              { name: "_nonce", internalType: "uint256", type: "uint256" },
            ],
            name: "getTransactionHash",
            outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
            stateMutability: "view",
          },
        ],
        functionName: "getTransactionHash",
        args: [
          multisend,
          BigInt(0),
          multisendCall,
          1,
          0n,
          0n,
          0n,
          zeroAddress,
          zeroAddress,
          BigInt(nonce),
        ],
        chainId: Number(chainId) as CHAIN_ID,
        allowFailure: false,
      };

      nonces[safeAddress.toLowerCase() as Address] = nonce + 1;
      return contract;
    },
  );

  const hashList = (
    await readContracts(wagmiConfig, {
      contracts: contracts as any,
    })
  ).map(hash => hash.result) as Hash[];

  const batchesApproves = [];

  for (const [index, batch] of safeBatches.entries()) {
    if (!hashList[index]) {
      batchesApproves.push(false);
    } else {
      const batchApprovedHashes = await readContracts(wagmiConfig, {
        contracts: owners[batch.safeAddress.toLowerCase() as Address].map(
          owner => ({
            address: batch.safeAddress,
            abi: [
              {
                type: "function",
                inputs: [
                  { name: "", internalType: "address", type: "address" },
                  { name: "", internalType: "bytes32", type: "bytes32" },
                ],
                name: "approvedHashes",
                outputs: [
                  { name: "", internalType: "uint256", type: "uint256" },
                ],
                stateMutability: "view",
              },
            ],
            functionName: "approvedHashes",
            args: [owner, hashList[index]],
            chainId: Number(batch.chainId) as CHAIN_ID,
            allowFailure: false,
          }),
        ),
      });

      const isApproved =
        batchApprovedHashes.find(
          ({ result }) => !!result && Number(result as any as BigInt) !== 0,
        ) !== undefined;

      batchesApproves.push(isApproved);
    }
  }

  return batchesApproves;
};
